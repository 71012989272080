import React from 'react';
import { Button } from 'react-bootstrap';
interface ExploreProps {
    onContinue: () => void;
}

const Explore: React.FC<ExploreProps> = ({ onContinue, ...props }) => {
    return (
        <div>
            <p>
                Och die piet Maaike altijd maar laat in de weer,<br />
                daar op de zolder keer op keer.
            </p>
            <p>
                Ook Roeland in zijn eigen wereld verzonken,<br />
                in Home Assistant wordt nog wat aaneengeklonken.
            </p>
            <p><Button variant="light" onClick={() => onContinue()}>🔔 Deurbel!</Button></p>
        </div >
    );
};

export default Explore;
