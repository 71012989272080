import React from 'react';
import { Button } from 'react-bootstrap';
interface DoorbellProps {
    onContinue: () => void;
}

const Doorbell: React.FC<DoorbellProps> = ({ onContinue, ...props }) => {
    return (
        <div>
            <p>
                Maaike hoorde het niet maar zag het wel,<br />
                daar ging toch heus de deurbel.
            </p>
            <p>
                Dat komt door het Roelands vernuftig geknutsel,<br />
                soms is hij echt dagen aan de frutsel.
            </p>
            <p>
                Schakelt de hulp in van Piet met een vraag of twee,<br />
                en die helpt hem weer voort, heel gedwee.
            </p>
            <p><Button variant="light" onClick={() => onContinue()}>🚶‍♂ Loop naar beneden...</Button></p>
        </div >
    );
};

export default Doorbell;
