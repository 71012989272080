export enum Status {
    Welcome,
    Kungfu,
    Explore,
    Doorbell,
    WalkDown,
    Playground
  }
  
  export interface Actions {
    RingDoorbell: () => void,
    PrinterLights: (state : boolean) => void,
    GarageLights: (state : boolean) => void,
    LivingRoomLights: (state : boolean) => void,
    FirstFloorLights: (state : boolean) => void,
    AtticLights: (state : boolean) => void,
    SwitchSlide: (newSlide: Status) => void,
};
