import React from 'react';
import { Container, Stack, Button, FormLabel } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { Actions } from '../Actions';
interface PlaygroundProps {
    onReset: () => void;
    actions: Actions;
}

interface SwitchProps {
    onToggle: (newState: boolean) => void;
    initial: boolean;
    label: string;
}

const Switch: React.FC<SwitchProps> = ({ onToggle, label, initial, ...props }) => {
    const [state, setState] = React.useState(initial);
    const toggle = () => {
        setState(!state);
        onToggle(!state);
    };
    return (
        <Container>
            <BootstrapSwitchButton checked={state} onstyle="success" offstyle="secondary" onChange={() => toggle()} />
            <FormLabel>{label}</FormLabel>
        </Container>
    );
}

const Playground: React.FC<PlaygroundProps> = ({ onReset, actions, ...props }) => {
    return (
        <div style={{ paddingTop: "1em" }}>
            <Stack gap={3} direction="horizontal" style={{ paddingBottom: "2em" }}>
                <Stack gap={3}>
                    <Switch initial={false} label={"💡🖨︎ 3d printer"} onToggle={(newState) => actions.PrinterLights(newState)} />
                    <Switch initial={true} label={"💡🏡 Garage & buiten"} onToggle={(newState) => actions.GarageLights(newState)} />
                    <Container>
                        <Button onClick={() => actions.RingDoorbell()}>🔔 Deurbel</Button>
                    </Container>
                </Stack>
                <Stack gap={3}>
                    <Switch initial={true} label={"💡🛋 Woonkamer"} onToggle={(newState) => actions.LivingRoomLights(newState)} />
                    <Switch initial={true} label={"💡🛌🏾 1e verdieping"} onToggle={(newState) => actions.FirstFloorLights(newState)} />
                    <Switch initial={true} label={"💡🖥️ Zolder"} onToggle={(newState) => actions.AtticLights(newState)} />

                </Stack>
            </Stack>
            <p><Button variant="danger" onClick={() => onReset()}>Reset - Terug naar gedicht!</Button></p>
        </div >
    );
};

export default Playground;
