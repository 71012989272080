import React from 'react';
import './App.css';

import { useSound } from 'use-sound';

import Welcome from './slides/Welcome';
import Kungfu from './slides/KungFu';
import Explore from './slides/Explore';
import Doorbell from './slides/Doorbell';
import WalkDown from './slides/WalkDown';
import Playground from './slides/Playground';
import { useOrientation } from '@uidotdev/usehooks';
import { Actions, Status } from './Actions';

function switchLights(newState: boolean, lightType: string) {
  const url = "/api/lights/" + lightType + "/" + (newState ? "on" : "off");
  const xhr = new XMLHttpRequest();
  xhr.open('POST', url, true);
  xhr.send();
}

function App() {
  const [doorbellSound] = useSound("/deurbel.mp3");
  const [status, setStatus] = React.useState(Status.Welcome);
  const orientation = useOrientation();
  const MyActions: Actions = {
    RingDoorbell: () => {
      doorbellSound();
    },
    PrinterLights: (newState: boolean) => switchLights(newState, "printer"),
    GarageLights: (newState: boolean) => switchLights(newState, "garage"),
    LivingRoomLights: (newState: boolean) => switchLights(newState, "livingroom"),
    FirstFloorLights: (newState: boolean) => switchLights(newState, "firstfloor"),
    AtticLights: (newState: boolean) => switchLights(newState, "attic"),

    SwitchSlide: (newSlide: Status) => {
      setStatus(newSlide);
      if (newSlide === Status.Doorbell) doorbellSound();
      const url = "/slide/" + newSlide;
      const xhr = new XMLHttpRequest();
      xhr.open('POST', url, true);
      xhr.send();
    }
  };
  const nextSlide = () => {
    MyActions.SwitchSlide(status + 1);
  };

  switch (status) {
    case Status.Welcome:
      if (orientation.type !== 'landscape-primary') {
        return (
          <div className="App">
            <header className="App-header">
              <p>
                Draai je scherm naar landscape! 📱🔄
              </p>
            </header>
          </div>
        );
      }
      return <Welcome onContinue={nextSlide} />;
    case Status.Kungfu:
      if (orientation.type !== 'landscape-primary') {
        return (
          <div className="App">
            <header className="App-header">
              <p>
                Draai je scherm naar landscape! 📱🔄
              </p>
            </header>
          </div>
        );
      }
      return <Kungfu onContinue={nextSlide} />;
    case Status.Explore:
      if (orientation.type !== 'landscape-primary') {
        return (
          <div className="App">
            <header className="App-header">
              <p>
                Draai je scherm naar landscape! 📱🔄
              </p>
            </header>
          </div>
        );
      }
      return <Explore onContinue={nextSlide} />;
    case Status.Doorbell:
      if (orientation.type !== 'landscape-primary') {
        return (
          <div className="App">
            <header className="App-header">
              <p>
                Draai je scherm naar landscape! 📱🔄
              </p>
            </header>
          </div>
        );
      }
      return <Doorbell onContinue={nextSlide} />;
    case Status.WalkDown:
      if (orientation.type !== 'landscape-primary') {
        return (
          <div className="App">
            <header className="App-header">
              <p>
                Draai je scherm naar landscape! 📱🔄
              </p>
            </header>
          </div>
        );
      }

      return <WalkDown onContinue={nextSlide} />;
    case Status.Playground:
      return <Playground onReset={() => setStatus(Status.Welcome)} actions={MyActions} />
  }
}

export default App;
