import React from 'react';
import { Button } from 'react-bootstrap';
interface WalkDownProps {
    onContinue: () => void;
}

const WalkDown: React.FC<WalkDownProps> = ({ onContinue, ...props }) => {
    return (
        <div>
            <p>
                Zo beneden gaat natuurlijk vanzelf het licht aan,<br />
                dat heeft Piet weer handig gedaan.
            </p>
            <p>
                Nu zijn alle presentjes in het licht gezet,<br />
                die zorgen vast voor nog meer pret.
            </p>
            <p>
                Roeland komt geregeld in Nieuw-Vennep voor wat gereedschap,<br />
                onder andere een ding waarmee je het glas schoon krab(t).
            </p>
            <p>
                Iets knippen was ook nog niet zo eenvoudig zag de Sint op je lijstje,<br />
                en voor vasthouden zocht hij ook naar iets voor een leuk prijsje.
            </p>
            <p>
                Sinterklaas lacht zijn plan volbracht,<br />
                die smart-home piet heeft het prachtig bedacht!
            </p>
            <p>

                Liefs Sint en Piet.
            </p>
            <p>
                PS. Het gedicht is klaar maar spelen kan nog steeds,<br />
                tot de piet weer terug naar huis racet.
            </p>
            <p><Button variant="light" onClick={() => onContinue()}>🛝 Speeltuin!</Button></p>
        </div >
    );
};

export default WalkDown;
