import React from 'react';
import { Button } from 'react-bootstrap';
interface WelcomeProps {
    onContinue: () => void;
}

const Welcome: React.FC<WelcomeProps> = ({ onContinue, ...props }) => {
    return (
        <div>
            <p>
                Welkom Roeland bij het Smart Pietenhuis,<br />
                voor menig piet zijn geheimen thuis.
            </p>
            <p>
                De magie van je surprises komt hier tot leven,<br />
                maar wacht, heeft nu op het eind de printer het begeven?
            </p>
            <p>
                3D-Printers, afstelling knoppen het is echt een heel gedoe,<br />
                Roeland is er handig mee, en maakt het met zijn kung-fu.
            </p>
            <p><Button variant="light" onClick={() => onContinue()}>🥋 Kung-fu Move</Button></p>
        </div >
    );
};

export default Welcome;
