import React from 'react';
import { Button } from 'react-bootstrap';
interface KungFuProps {
    onContinue: () => void;
}

const KungFu: React.FC<KungFuProps> = ({ onContinue, ...props }) => {
    return (
        <div>
            <p>
                Ah de printer is weer gereed verklaard.<br />
                tijd om het huis te verkennen wordt er gebaard.
            </p>
            <p><Button variant="light" onClick={() => onContinue()}>🕵️‍♂️ Huis verkennen 🔍️</Button></p>
        </div >
    );
};

export default KungFu;
