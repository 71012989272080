import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Container, Navbar } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Add this line to import Bootstrap CSS

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Navbar expand="lg" bg="dark" variant="dark" sticky="top" className="navbar-brand-center">
      <Container fluid>
        <div style={{ textAlign: "center", display: 'block', width: '100%' }}>
          <Navbar.Brand style={{ marginRight: 'unset', display: "inline-block" }}>Smart Pietenhuis</Navbar.Brand>
        </div>
      </Container>
    </Navbar>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
